/***** EcoCalc2.css start ****/
.EcoCalc { padding: 20px;  }
.EcoCalc .map-features-menu {
  display: flex;
  position: relative;
  width: 460px;
  max-width: 100vw;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,.61);
  z-index: 10;
  transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.EcoCalc-panel-closed .map-features-menu {
  /* IE11 does not support translate in conjunction with calc */
  transform: translateX(-500px);
  transform: translateX(calc(-100% - 40px));
}
.EcoCalc-panel-closed.EcoCalc-panel-hide .map-features-menu {
  opacity: 0;
}
.EcoCalc-show-panel {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 3;
  width: 25px;
  height: 50px;
  padding: 16px 4px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,.61);
  transform: translateX(480px);
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.EcoCalc-panel-closed-icon {
  margin-top: 3px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: rotate(180deg);
}
.EcoCalc-panel-closed .EcoCalc-panel-closed-icon {
  transform: rotate(0);
  margin-top: 0;
}
.EcoCalc-panel-closed .EcoCalc-show-panel {
  transform: translateX(0);
}
.EcoCalc .panel-body {
  padding: 0;
  flex: 0 0 calc(100% - 60px);
  margin: 90px 30px 30px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.EcoCalc-info-active .panel-body {
  /* - map-features-menu width - (panel-body margin-left +panel-body margin-right)
   * => -460
  */
  transform: translateX(-460px);
}
.EcoCalc .panel-heading {
  margin: 0 0 20px;
  padding: 0;
  line-height: 1;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 300;
}
.EcoCalc-options {
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 30px;
  right: 30px;
  left: 30px;
  z-index: 2;
}
.EcoCalc-options-item {
  /*float: right;*/
  display: inline-block;
  padding: 7px 9px;
  color: #ccc;
  fill: #ccc;
  text-decoration: none;
  transition: all 150ms cubic-bezier(0.86, 0, 0.07, 1);
}
.EcoCalc-options-item:hover {
  /*background: #eee;*/
  color: #666;
  fill: #666;
}
.EcoCalc-options-item .active,
.EcoCalc-options-item.active {
  color: #0076c9;
  fill: #0076c9;
}
.EcoCalc .panel-body h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 300;
}

.EcoCalc h3 {
  font-size: 19px;
}
.EcoCalc form:after {
  content: "";
  display: block;
  clear: both;
}
.EcoCalc input[type="number"] {
  text-align: right;
}
.EcoCalc-input {
  width: 100%;
  height: 36px;
  padding: 7px;
  margin-right: -1px;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: 0;
  font-size: 14px;
  -webkit-appearance: none;
  transition: border-color 200ms;
}
.EcoCalc-comment-area:focus,
.EcoCalc-input:focus {
  border-color: #0076c9;
}
.EcoCalc-autocompleteContainer {
  position: absolute;
  top: 100%;
  top: calc(100% - 3px);
  background-color: white;
  border: 1px solid #0076c9;
  border-top: 0;
  width: 100%;
}
.EcoCalc-autocompleteItem {
  background-color: #ffffff;
  padding: 10px;
  color: #555555;
  cursor: pointer;
}
.EcoCalc-autocompleteItemActive {
  background-color: #fafafa;
}
.EcoCalc .Select-control {
  border-radius: 0;
  font-size: 14px;
}

.EcoCalc-transport-mode-selection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.EcoCalc-transport-checkbox {
  position: relative;
  margin: 0 5px;
  width: 35px;
  height: 35px;
  border: 1px solid #ccc;
  border-radius: 5000px;
  fill: #999;
  transition: all 150ms;
}
.EcoCalc-transport-checkbox.checked {
  border: 1px solid #0076c9;
  fill: #0076c9;
}
.EcoCalc-transport-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.EcoCalc-footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.EcoCalc-footer .attribution {
  margin: 0;
  color: #333;
  text-decoration: none;
  font-size: 13px;
  opacity: 0.5;
  transition: opacity 250ms;
}
/*.EcoCalc-footer .attribution span {
  font-size: 1.3em;
}*/
.EcoCalc-footer .attribution:hover {
  opacity: 1;
}
.EcoCalc button,
.EcoCalc .btn {
  display: block;
  width: 100px;
  padding: 7px;
  background-color: #fff;
  color: #666;
  border: 1px solid #666;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
  font-family: Klavika-Regular,'Source Sans Pro',Arial,tahoma,sans-serif;
  transition: all .3s ease-in-out;
}
.EcoCalc button:hover,
.EcoCalc .btn:hover,
.EcoCalc button:focus,
.EcoCalc .btn:focus {
  background: #0076c9;
  border-color: #0076c9;
  color: #fff;
}
.EcoCalc .status-error {
  color: #008CFF;
  font-size: 0.8em;
  margin-top: 0px;
}
.EcoCalc .status-success {
  color: #0AA300;
  font-size: 0.8em;
  margin-top: 0px;
}
.EcoCalc-comment-area {
  border: 1px solid #ccc;
  width: 100%;
}

.EcoCalc-result {
  margin-top: 30px;
  margin-bottom: 10px;
  list-style: none;
  clear: both;
  padding-left: 0;
}
.EcoCalc-result-icon {
  height: 0;
  width: 60px;
  transform: translateY(20px);
}
.EcoCalc-result-item h4 {
  display: flex;
  margin-bottom: 5px;
  font-size: 16px;
}
.EcoCalc-result-start {
  flex: 1 1 0;
  margin: 0 10px;
  text-align: right;
}
.EcoCalc-result-end {
  flex: 1 1 0;
  margin: 0 10px;
  text-align: left;
}
.EcoCalc-calculations-line {
  display: flex;
  justify-content: center;
  margin: 0;
}
.EcoCalc-calculations-value {
  flex: 1 1 0;
  margin: 0 10px 10px;
  text-align: left;
}
.EcoCalc-result-summary {
  margin-top: 15px;
}
.EcoCalc-result-item .EcoCalc-summary-head {
  display: block;
  position: relative;
  text-align: center;
}
.EcoCalc-summary-head::after {
  display: block;
  content: "";
  height: 1px;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 15%;
  z-index: -1;
  background: #ccc;

}
.EcoCalc-summary-head span {
  padding: 0 15px;
  background: #fff;
}
.EcoCalc-calculations-value:first-of-type {
  text-align: right;
}
.EcoCalc-calculations-value:only-of-type {
  text-align: center;
}
.EcoCalc-calculations-value b {
  font-size: 24px;
  font-weight: 300;
  color: #333;
}
.EcoCalc .map-layer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.leaflet-container {
  height: 100%;
  width: 100%;
}
.PlaceInput {
  margin: 0 10px;
}
.EcoCalc-info-text p {
  margin-top: 0;
  font-size: 14px;
}
.EcoCalc-show,
.EcoCalc .EcoCalc-show {
  display: inline;
}
@media (max-width: 1300px) {
  .EcoCalc {
    font-size: 14px;
  }
  .EcoCalc .panel-heading {
    font-size: 25px;
  }
  .EcoCalc .map-features-menu {
    max-width: 350px;
  }
  .EcoCalc-panel-closed .map-features-menu {
    transform: translateX(-390px);
    transform: translateX(calc(-100% - 40px));
  }
  .EcoCalc-info-active .panel-body {
    /* - map-features-menu width
     * => -350
     */
    transform: translateX(-350px);
  }
  .EcoCalc .panel-body h4 {
    font-size: 16px;
  }
  .EcoCalc-result-item {
    height: 80px;
  }
  .EcoCalc-calculations-value b {
    font-size: 20px;
  }
  .EcoCalc button {
    width: 80px;
    font-size: 12px;
  }
  .EcoCalc-show-panel {
    transform: translateX(370px);
  }
}
@media (max-width: 480px) {
  .EcoCalc {
    padding: 0;
  }
  .EcoCalc .map-features-menu {
    max-height: initial;
  }
  .EcoCalc-show-panel {
    transform: translateX(350px);
  }
}
@media screen {
  .sr-only {
    display: none;
  }
}
/***** EcoCalc2.css end ****/